import apiClient from '../../../services/apiClient';
import apiConfig from '../../../services/apiConfig';

export const AUTENTICACAO_TOKEN = 'AUTENTICACAO_TOKEN';
export const AUTENTICACAO_ESTOQUE = 'AUTENTICACAO_ESTOQUE';
export const AUTENTICACAO_ANONIMA = 'AUTENTICACAO_ANONIMA';
export const AUTENTICACAO_ALIAS_CATALOGO_DIGITAL = 'AUTENTICACAO_ALIAS_CATALOGO_DIGITAL';
export const ROTINA_ORIGEM = {
  ENTREGA_FUTURA: 'EntregaFutura',
};

import { PRODUTOS_API, NOMES_PRODUTOS_API } from '@/core/enums/produtos-api.enum';

const idConfigAmbiente = process.env.VUE_APP_ID_CONFIG_AMBIENTE || null;

const registrarUrlAPIs = function (produtos) {
  apiConfig.setUrlEsApi(produtos.filter((x) => x.Produto === PRODUTOS_API.ES)[0].Url);
  apiConfig.setUrlNFeApi(produtos.filter((x) => x.Produto === PRODUTOS_API.NFE)[0].Url);
  apiConfig.setUrlCrmApi(produtos.filter((x) => x.Produto === PRODUTOS_API.CRM)[0].Url);
  apiConfig.setUrlFiscalApi(produtos.filter((x) => x.Produto === PRODUTOS_API.Fiscal)[0].Url);
  apiConfig.setUrlFaturamentoApi(produtos.filter((x) => x.Produto === PRODUTOS_API.Faturamento)[0].Url);
  apiConfig.setUrlFinanceiroApi(produtos.filter((x) => x.Produto === PRODUTOS_API.AdmFinanceiro)[0].Url);
  apiConfig.setUrlLisApi(produtos.filter((x) => x.Produto === PRODUTOS_API.LIS)[0].Url);
  apiConfig.setUrlSuprimentosApi(produtos.filter((x) => x.Produto === PRODUTOS_API.Suprimentos)[0].Url);
  apiConfig.setUrlImagensProdutosApi(getUrlApi(produtos, PRODUTOS_API.ProdutosImagens));
  apiConfig.setUrlConnectHub(getUrlApi(produtos, PRODUTOS_API.ConnectHub));
};

const getUrlApi = (produtos, chaveApi) => {
	let produto = produtos.filter((x) => x.Produto === chaveApi);
	if ((produto) && (produto.length > 0)) {
		return produto[0].Url;
	}	
	return "";
};

const getDefaultState = () => ({
  usuario: null,
  empresa: null,
  autenticado: false,
  tokenTemporarioLogin: null,
  LmxAuthToken: null,
  tokenJWT: null,
  portaisUsuario: null,
  metodoAutenticacao: {
    tipo: null,
    nomeAplicacaoOrigem: null,
  },
});

const state = getDefaultState();

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState());
  },

  login: (state, payload) => {
    state.usuario = payload.usuario;
    state.tokenTemporarioLogin = payload.tokenTemporario;
    state.LmxAuthToken = payload.tokenTemporario;
    state.portaisUsuario = payload.portais;
    state.autenticado = false;
    state.tokenJWT = payload.tokenJWT;
  },

  selecionarEmpresa: (state, payload) => {
    state.empresa = payload;
    state.autenticado = true;
  },

  atualizarMetodoAutenticacao: (state, payload) => {
    state.metodoAutenticacao.tipo = payload.tipo;
    state.metodoAutenticacao.nomeAplicacaoOrigem = payload.nomeAplicacaoOrigem;
  },

  setarAutenticado: (state) => {
    state.autenticado = true;
  },

  updateTokenJWT: (state, payload) => {
    state.tokenJWT = payload;
  },
};
const actions = {
  async selecionarEmpresa({ state, commit }, payload) {
    try {
      const urlRequest = apiConfig.getUrlErpAdminApi() + '/api/Autenticacao/SelecionarEmpresa';

      const requestData = {
        IdPortal: payload.IdPortal,
        IdEmpresa: payload.IdEmpresa,
        Produtos: NOMES_PRODUTOS_API,
      };

      const headers = { headers: { AuthToken: state.tokenTemporarioLogin } };

      if (idConfigAmbiente && idConfigAmbiente != 0) requestData.IdAmbienteProdutos = idConfigAmbiente;

      const { data } = await apiClient.post(urlRequest, requestData, headers);

      var portalSelecionado = state.portaisUsuario.filter((p) => p.Id == payload.IdPortal)[0];
      var empresaSelecionada = portalSelecionado.Empresas.filter((e) => e.Id == payload.IdEmpresa)[0];

      apiConfig.setToken(data.TokenJWT);

      commit('selecionarEmpresa', {
        Id: empresaSelecionada.Id,
        Nome: empresaSelecionada.Nome,
        Portal: portalSelecionado.Id,
      });

      commit('updateTokenJWT', data.TokenJWT);

      registrarUrlAPIs(data.Produtos);
      commit('setarAutenticado');
    } catch (error) {
      apiConfig.reset();
      commit('selecionarEmpresa', null);
      return error;
    }
  },

  async executarLogin({ commit, dispatch }, payload) {
    try {
      const urlRequest = apiConfig.getUrlErpAdminApi() + '/api/Autenticacao/Login';

      const requestData = {
        Login: payload.login,
        Senha: payload.senha,
      };

      const { data } = await apiClient.post(urlRequest, requestData);

      commit('login', {
        usuario: data.Usuario,
        portais: data.Portais,
        tokenTemporario: data.TokenTemporario,
      });

      commit('atualizarMetodoAutenticacao', {
        tipo: AUTENTICACAO_ESTOQUE,
        nomeAplicacaoOrigem: '',
      });

      return data;
    } catch (error) {
      apiConfig.reset();
      dispatch('handleLogout');

      return error;
    }
  },

  async handleLogout({ commit, dispatch }) {
    setTimeout(() => {}, 1000);

    function logout(dispatch, commit) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          apiConfig.reset();
          dispatch('resetAllState', null, { root: true });
          commit('reset');
          return resolve();
        }, 1000);
      });
    }

    await logout(dispatch, commit);
  },

  async loginPorToken({ commit, dispatch }, payload) {
    try {
			const headers = { AuthToken: payload.token };
      const urlRequest = apiConfig.getUrlErpAdminApi() + '/api/Autenticacao/LoginPorToken';

      const requestData = {
        Produtos: NOMES_PRODUTOS_API,
				IdAmbienteProdutos: payload.idConfigAmbiente,
      };

      const { data } = await apiClient.post(urlRequest, requestData, { headers });

      commit('login', {
        usuario: data.Usuario,
        portais: [],
        tokenTemporario: payload.token,
        tokenJWT: data.TokenJWT,
      });

      registrarUrlAPIs(data.Produtos);
      apiConfig.setToken(data.TokenJWT);

      commit('selecionarEmpresa', {
        Id: data.Empresa.Id,
        Nome: data.Empresa.Nome,
        Portal: data.Portal.Id,
      });

      commit('atualizarMetodoAutenticacao', {
        tipo: AUTENTICACAO_TOKEN,
        nomeAplicacaoOrigem: payload.nomeAplicacaoOrigem,
      });

      commit('setarAutenticado');
    } catch (error) {
      apiConfig.reset();
      dispatch('handleLogout');
      return error;
    }
  },
};

const getters = {
  autenticacaoUsuarioESenhaRealizada(state) {
    return state.usuario && state.tokenTemporarioLogin;
  },

  portaisParaSelecao(state) {
    const ehSupervisorRede = state.usuario.SupervisorRede;

    var opcoes = state.portaisUsuario.map((portal) => {
      return {
        Id: portal.Id,
        Nome: portal.Nome,
        PortalLabel: `${portal.Id} - ${portal.Nome}`,
        Empresas: portal.Empresas.map((empresa) => {
          return {
            Id: empresa.Id,
            Nome: empresa.Nome,
            EmpresaLabel: `${empresa.Id} - ${empresa.Nome}` + (ehSupervisorRede ? ` (${portal.Id} - ${portal.Nome})` : ``),
            IdPortalEmpresa: `${empresa.Id}-${portal.Id}`,
          };
        }),
      };
    });

    return opcoes;
  },

  nomeAplicacao() {
    return process.env.VUE_APP_NOME_APLICACAO;
  },

  isLoading(state, getters, rootState, rootGetters) {
    return rootGetters['manageLoadingStates/is']('loginPorToken autenticacao');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
