const getLoadingState = () => {
  const state = {};

  state.nomesLoadingsPorAction = {
    obterArquivoModelo: 'obterArquivoModelo importacao',
    buscarDadosIniciaisFormulario: 'buscarDadosIniciaisFormulario importacao',
    obterDadosIniciaisPainelImportacoes: 'obterDadosIniciaisPainelImportacoes importacao',
    downloadArquivoComDivergencias: 'downloadArquivoComDivergencias importacao',
    buscarInconsistencias: 'buscarInconsistencias importacao',
    importarProdutosPorArquivo: 'importarProdutosPorArquivo importacao',
    cancelarImportacao: 'cancelarImportacao importacao',
    conectarHubProdutosImportacao: 'conectarHubProdutosImportacao importacao',
    atualizarListagemPainelImportacoes: 'atualizarListagemPainelImportacoes importacao',
    filtrarImportacoes: 'filtrarImportacoes importacao',
  };

  const {
    buscarDadosIniciaisFormulario,
    obterDadosIniciaisPainelImportacoes,
    downloadArquivoComDivergencias,
    buscarInconsistencias,
    importarProdutosPorArquivo,
    cancelarImportacao,
    conectarHubProdutosImportacao,
    atualizarListagemPainelImportacoes,
    filtrarImportacoes,
  } = state.nomesLoadingsPorAction;

  state.loadingsPorComponente = {
    importacaoDeProdutos: [cancelarImportacao, downloadArquivoComDivergencias],
    importacaoTable: [
      obterDadosIniciaisPainelImportacoes,
      conectarHubProdutosImportacao,
      atualizarListagemPainelImportacoes,
      filtrarImportacoes,
    ],
    importacaoModalFormulario: [buscarDadosIniciaisFormulario, importarProdutosPorArquivo],
    importacaoModalErros: [buscarInconsistencias],
  };

  return { ...state };
};

const getStoreState = () => ({
  hubConnection: null,

  queryFiltroListagem: '',

  isShowModalErros: false,
  isShowModalFormulario: false,

  importacaoSelecionada: null,

  listaDeImportacoes: [],
  listaDeImportacoesFiltradas: [],
  listaEmpresas: [],
  parametrosGlobais: {
    ProdutoDisponivelEmpresa: false,
  },

  situacoesImportacao: {
    processando: 1,
    concluido: 2,
    concluidoComInconsistencias: 3,
    cancelado: 4,
  },

  loading: getLoadingState(),
});

export const state = getStoreState();
